import React from 'react';
import heroImage from '../img/slijkvisserbanner.png'
import {withTranslation} from "react-i18next";
import {Col, Container} from "react-bootstrap";


function Hero(props) {
    return (
        <div id="hero" className="single-page section"
             style={{
                 height: "500px !important",
                 minHeight: "500px",
                 borderBottom: "1px solid #cbb27c",
                 backgroundPosition: "center",
                 backgroundSize: "cover",
                 backgroundImage: `url(${heroImage})`,
                 position: "relative",
                 boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.50)"}}>
            <section style={{background:"none"}}>
            <Container>
                <div className="row blurb scrollme animateme justify-content-center" data-when="exit" data-from="0" data-to="1" data-opacity="0"
                     data-translatey="100" >
                    <Col md={10} className={"col-md-offset-1"}>
                        <h1>{props.title1}</h1>
                        <h2>{props.title2}</h2>
                    </Col>
            </div>
            </Container>
            </section>
        </div>
    );
}

export default withTranslation()(Hero);
