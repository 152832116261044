import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import React from "react";
import {withTranslation} from "react-i18next";
import "./BeerRow";

const MAX_AMOUNT_OF_PARAGRAPHS = 6;
const BeerRow = (props) => {
    const text = [];
    for(let i =0; i <= MAX_AMOUNT_OF_PARAGRAPHS;i++) {
        if (props.t(`ourBeerSlijkvisser${i}_${props.count}`) !== `ourBeerSlijkvisser${i}_${props.count}`) {
            text.push(<p>{props.t(`ourBeerSlijkvisser${i}_${props.count}`)}</p>)
        }
    }
    return (
        <Row>
            <Col sm={5} className="matchHeight d-flex justify-content-center align-items-center">
                <img
                    src={`${process.env.PUBLIC_URL}/beerimg/bottleAndGlass/${props.img}`}
                    alt="About our Brewery"
                    className="beer-image"
                />
            </Col>

            <Col sm={6} className="matchHeight" id={props.anchor} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <section className="alignMiddle mobile-center" style={{ background: "none", color: "white" }}>
                    <header>
                        <h1>{props.t(props.name.toLowerCase())}</h1>
                        <h2>{props.subName}</h2>
                    </header>
                    {text}
                    {props.t(`ourBeerSlijkvisserRemark_${props.count}`) !== `ourBeerSlijkvisserRemark_${props.count}` && (
                        <p style={{ color: "red" }}>{props.t(`ourBeerSlijkvisserRemark_${props.count}`)}</p>
                    )}
                    <Link to="/find" className="btn btn-default"><span>{props.t('ourBeerWhere')}</span></Link>
                </section>
            </Col>

            {(props.t(`ourBeerReviewTitle_${props.count}`) !== `ourBeerReviewTitle_${props.count}`)?
                <Col>
                    <div>
                        <h1>{props.t(`ourBeerReviewTitle_${props.count}`)}</h1>
                        <blockquote className={"blockquote"}>
                            <p>{props.t(`ourBeerReviewContent_${props.count}`)}</p>
                        </blockquote>
                        <cite style={{color: "white"}}>{props.t(`ourBeerReviewSource_${props.count}`)}</cite>
                    </div>
                </Col> : ""
            }

        </Row>
    )
}
export default withTranslation()(BeerRow);
