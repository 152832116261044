import React from 'react';
import Hero from "../components/Hero";
import How from "../components/How";
import Carousel from "../components/AboutBrewery";
import Beers from "../components/Beers";
import Stats from "../components/Stats";
import {withTranslation} from "react-i18next";
import Wrapper from "../components/Wrapper";
import ScrollButton from "../components/ScrollButton";
import ScrollToTop from "../components/ScrollToTop";
import Helmet from "../components/Helmet";


function Home({t, i18n}) {

    return (
        <Wrapper>
            <Helmet title={`${t('homeTitle1')} ${t('homeTitle2')}`} description={t('homeTitle2')}/>
            <ScrollToTop/>
            <Hero title1={t('homeTitle1')} title2={t('homeTitle2')}/>
            <How/>
            <Carousel withButton={true}/>
            <Beers/>
            <Stats/>
            <ScrollButton/>
        </Wrapper>
    );
}

export default withTranslation()(Home);
