import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Find from "./pages/Find";
import OurBeer from "./pages/OurBeer";
import OurStory from "./pages/OurStory";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import React from "react";
export default () => (
        <Routes>
            <Route path="/find" element={<Find/>}/>
            <Route path="/beers" element={<OurBeer/>}/>
            <Route path="/story" element={<OurStory/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/" element={<Home/>}/>
        </Routes>
);