import React from 'react';
import blockBg from '../img/block-bg-1.jpg'
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import hops from "../img/bannerIcons/hops.svg";
import sun from "../img/sun.svg"
import barrel from "../img/barrel.svg"
import bottlecrate from "../img/bottlecrate.svg"
import {Link} from "react-router-dom";

function How({t, i18n}) {

return (
    <Container fluid={true}>
        <section style={{background:"rgb(33, 37, 41)" , padding: "0px"}}>
            <Row>
                <Col sm={6} style={{height: 395,padding:0}} className={"matchHeight"}>
                    <section className="alignMiddle mobile-center" style={{background:"rgb(33, 37, 41)",paddingLeft:80 }}>
                        <header>
                            <h1>{t('about1')}</h1>
                            <h2>{t('about2')}</h2>
                        </header>
                        <p>{t('about3')}</p>
                        <Link to="/story" className="btn btn-default"><span>{t('generalBtnMore')}</span></Link>
                    </section>
                </Col>
                <Col sm={6} className={"matchHeight hideInMobile"}
                     style={{backgroundImage: `url(${blockBg})` ,padding: 0, height: 395}}>
                    <Row>
                        <Col xs={6} style={iconGridStyle}>
                            <img src={hops} alt={"hops"}/>
                            <h4 style={{fontSize: "0.8rem"}}>{t('aboutBlock1Title')}</h4>
                        </Col>
                        <Col xs={6} style={iconGridStyle}>
                            <img src={sun} alt={"sun"}/>
                            <h4 style={{fontSize: "0.8rem"}}>{t('aboutBlock2Title')}</h4>
                        </Col>
                        <Col xs={6} style={iconGridStyle}>
                            <img src={barrel} alt={"barrel"}/>
                            <h4 style={{fontSize: "0.8rem"}}>{t('aboutBlock3Title')}</h4>
                        </Col>
                        <Col xs={6} style={iconGridStyle}>
                            <img src={bottlecrate} alt={"bottlecrate"}/>
                            <h4 style={{fontSize: "0.8rem"}} >{t('aboutBlock4Title')}</h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    </Container>
);
}

const iconGridStyle ={
    padding: "40px 50px",
paddingRight: "50px",
paddingLeft: "50px",
textAlign: "center",
borderRight: "1px solid rgba(255, 255, 255, .08)",
borderBottom: "1px solid rgba(255, 255, 255, .08)",
height: 197
};

export default withTranslation()(How);
