import React from 'react';
import {withTranslation} from "react-i18next";
import Hero from "../components/Hero";
import Stats from "../components/Stats";
import Wrapper from "../components/Wrapper";
import Carousel from "../components/AboutBrewery";
import logo from "../img/logo.png"
import ScrollToTop from "../components/ScrollToTop";
import ScrollButton from "../components/ScrollButton";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import brewery2017 from "../img/brewery/brewery2017.jpg";
import brewery2021_1 from "../img/brewery/brewery2021_1.jpg";
import brewery2021_2 from "../img/brewery/brewery2021_2.jpg";
import brewery2023 from "../img/brewery/Brewery2023.jpeg";
import Helmet from "../components/Helmet";

function OurStory({t,i18n}) {
    return (
        <Wrapper>
            <Helmet title={`${t('ourStoryHero1')} ${t('ourStoryHero2')}`} description={t('aboutBreweryContent')}/>
            <ScrollToTop/>
            <Hero title1={t('ourStoryHero1')} title2={t('ourStoryHero2')}/>
            <Carousel withButton={false}/>
            <div className="container-fluid light section">
                <Container>
                    <h1>2017</h1>
                        <Row>
                            <Picture img = {brewery2017} title={"2017"}/>
                        </Row>
                    <br/>
                    <h1>2021</h1>
                    <Row>
                        <Picture img = {brewery2021_1} title={"2021"}/>
                        <Picture img = {brewery2021_2} title={"2021"}/>
                        </Row>
                    <br/>
                    <h1>2022</h1>
                    <Row>
                        <Picture img = {brewery2023} title={"2022"}/>
                    </Row>
                </Container>
            </div>

            <div className="container-fluid dark section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="history-logo">
                                <img src={logo} alt="Logo" style={{maxWidth:"20%"}}/>
                            </div>
                            <div className="history">
                                <span className="date">2001</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate1')}</span>
                                    </header>
                                    <p>{t('ourStoryDate1Text')}</p>
                                </article>
                                <article className="scrollme animateme left" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate2')}</span>
                                    </header>
                                    <p>{t('ourStoryDate2Text')}</p>
                                </article>
                                <span className="date">2002</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.71, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate3')}</span>
                                    </header>
                                    <p>{t('ourStoryDate3Text')}</p>
                                </article>
                                <span className="date">2004</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate4')}</span>
                                    </header>
                                    <p>{t('ourStoryDate4Text')}</p>
                                </article>

                                <span className="date">2005</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate5')}</span>
                                    </header>
                                    <p>{t('ourStoryDate5Text')}</p>
                                </article>

                                <span className="date">2008</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate6')}</span>
                                    </header>
                                    <p>{t('ourStoryDate6Text')}</p>
                                </article>

                                <span className="date">2017</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate7')}</span>
                                    </header>
                                    <p>{t('ourStoryDate7Text')}</p>
                                </article>

                                <span className="date">2019</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate8')}</span>
                                    </header>
                                    <p>{t('ourStoryDate8Text')}</p>
                                </article>

                                <span className="date">2020</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate9')}</span>
                                    </header>
                                    <p>{t('ourStoryDate9Text')}</p>
                                </article>
                                <article className="scrollme animateme left" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate10')}</span>
                                    </header>
                                    <p>{t('ourStoryDate10Text')}</p>
                                </article>

                                <span className="date">2021</span>
                                <article className="scrollme animateme right" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate11')}</span>
                                    </header>
                                    <p>{t('ourStoryDate11Text')}</p>
                                </article>

                                <article className="scrollme animateme left" data-when="enter" data-from="0.75"
                                         data-to="0" data-opacity="0"
                                         style={{opacity: 0.97, transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"}}>
                                    <header>
                                        <span>{t('ourStoryDate12')}</span>
                                    </header>
                                    <p>{t('ourStoryDate12Text')}</p>
                                </article>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Stats/>
            <ScrollButton/>
        </Wrapper>
    );
}

const Picture = (props) => {
    return (<Col sm={6} className={"matchHeight"} style={{height: 442,   position: "relative",
        width: "220px"}}>
        <p style={{
            position: "absolute",
            textAlign: "center",
            width: "100%",
            bottom: "0px",
            font: "400 18px/1, cursive",
            color: "#888",
            zIndex: 1
        }}>{props.title}</p>
        <img src={props.img} alt="About our Brewery" className="alignMiddle" style={{
            border: "10px solid #fff",
            borderBottom: "45px solid #fff",
            height : "100%",
            boxShadow: "3px 3px 3px #777"}}/>
    </Col>)
}

export default withTranslation()(OurStory);
