import React from 'react';


function Wrapper(props) {
    return (
        <div className="wrapper" style={{
            paddingBottom: 431,
            overflowX: "hidden",
            background: "#1b1d1e"}}>
            {props.children}
        </div>
    );
}

export default Wrapper;