import React from 'react';
import {withTranslation} from "react-i18next";
import Hero from "../components/Hero";
import Wrapper from "../components/Wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationArrow} from '@fortawesome/free-solid-svg-icons'
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import ScrollToTop from "../components/ScrollToTop";
import ScrollButton from "../components/ScrollButton";
import Helmet from "../components/Helmet";

function Contact({t,i18n}) {
    return (
        <Wrapper>
            <Helmet title={`${t('contactHero1')}`} description={t('contactHero2')}/>
            <ScrollToTop/>
            <Hero title1={t('contactHero1')} title2={t('contactHero2')}/>
            <Container fluid={true} className={"dark"} style={{padding:0}}>
                <section style={{padding:0, background:"#212529"}}>
                    <Row>
                        <SocialMedia to={"https://www.facebook.com/brouwerijvandijck"} icon={['fab', 'facebook']} title={"Facebook"} content={t("contactFacebook")}/>
                        <SocialMedia to={"https://www.instagram.com/brouwerijvandijck"} icon={['fab', 'instagram']} title={"instagram"} content={t('contactInstagram')}/>
                        <SocialMedia to={"https://untappd.com/BrouwerijVandijck/beer"} icon={['fab', 'untappd']} title={"untappd"} content={t('contactUntappd')}/>
                        <SocialMedia to={"mailto:info@brouwerijvandijck.be"} icon={faEnvelope} title={"E-mail"} content={t('contactMail')}/>
                    </Row>
                </section>
            </Container>
            <Container fluid={true} style={{padding:0}}>
                <section>
                    <Row>
                            <Col sm={6} className={"matchHeight mobile-padding"} style={{height: "539px"}}>
                                <ul className="contact-list" style={{paddingTop:"100px",marginLeft:"50px"}}>
                                    <li>
                                        <FontAwesomeIcon icon={faLocationArrow}/>
                                        <div style={{marginLeft: "20px"}}>
                                            {t('contactAddress')}
                                            <span>Wagenmakersweg 32, Lommel, Belgium</span>
                                        </div>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        <div style={{marginLeft: "20px"}}>
                                            Email
                                            <span>info@brouwerijvandijck.be</span>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={6} className={"matchHeight"} style={{height: "500px"}}>
                                <section className="mobile-center" style={{height: "539px"}}>
                                    <header>
                                        <h1>{t('contactMessage1')}</h1>
                                        <h2 style={{color: "black"}}>{t('contactMessage2')}</h2>
                                    </header>
                                    <p style={{color: "black"}}>{t('contactMessage3')}</p>
                                </section>
                                <div className="grey-background"/>
                            </Col>
                    </Row>
                </section>
            </Container>
            <ScrollButton/>
        </Wrapper>
    );
}

const SocialMedia = ({to,icon,title,content}) => {
    return <Col sm={3} xs={6} className={"icon-grid"} style={{paddingTop: "15px", height: "216px"}}>
        <a target="_blank rel=noopener" href={to} style={{top: "auto"}}>
        <FontAwesomeIcon icon={icon}
                         style={{fontSize: "500%"}}/>
        <h4>{title}</h4>
        <p>{content}</p>
        </a>
    </Col>
};

export default withTranslation()(Contact);
