import React, {Suspense} from 'react';
import NavBar from "./components/StickyNavBar";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import i18n from "i18next";
import i18nextHttpBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

import LanguageChooser from "./pages/LanguageChooser";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    BrowserRouter as Router
} from "react-router-dom";

import Footer from "./components/Footer";
import Myrouter from "./router";

//load font awsome
library.add(fab);

//load translations
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(i18nextHttpBackend)
        .init({
            backend : { loadPath: 'https://ab-itteam.github.io/vandijckcontent/translations/{{lng}}/translation.json'},
            lng: "nl",
            fallbackLng: "en",
            interpolation: {
                escapeValue: false
            }
        });

function App() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
        <LanguageChooser>
            <Router>
                <NavBar/>
                <Myrouter/>
                <Footer/>
            </Router>
        </LanguageChooser>
        </Suspense>
    );
}

export default App;
