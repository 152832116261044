import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faEnvelope, faMap} from '@fortawesome/free-solid-svg-icons'
import {withTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Socials from "./Socials";
import {Link} from "react-router-dom";
import { HashLink as HLink } from 'react-router-hash-link';
import beerconfig from "../beerconfig.json";
import "./Footer.css";


function Footer({t, i18n}) {
    return (
        <footer>
            <Container style={{zIndex: 1}}>
                <Row>
                    <Col sm={2}>
                        <h6>{t('footerNavItem1')}</h6>
                        <ul>
                            <li><Link to="/">{t('navItem1')}</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={2}>
                        <h6>{t('footerNavItem2')}</h6>
                        <ul>
                            <li><Link to="/contact">{t('navItem4')}</Link></li>
                        </ul>
                    </Col>
                    <Col sm={2}>
                        <h6>{t('footerBeers')}</h6>
                        <ul>
                            <li><HLink smooth to={`/beers`}>{t('beerTitle1') + " " + t('beerTitle2')}</HLink></li>
                        </ul>
                    </Col>
                    <Col sm={5}>
                        <h6>{t('footerInformation')}</h6>
                        <p><FontAwesomeIcon icon={faMap}/> Wagenmakersweg 32, Lommel, Belgium</p>
                        <p><FontAwesomeIcon icon={faBuilding}/> BE0768.267.813</p>
                        <p><FontAwesomeIcon icon={faEnvelope}/> Info@brouwerijvandijck.be</p>
                    </Col>
                </Row>
                <Socials/>
                <div className="copyright">
                    <p>2020 - {new Date().getFullYear()} © Slijkvisser / <a href="https://www.ab-it.io/">Web design by AB-IT</a>
                    </p>
                    <a href="/privacy-policy.html">Privacy Policy</a>
                </div>
            </Container>
        </footer>
    );
}

export default withTranslation()(Footer);
